import React from 'react'
import { css } from '@emotion/react'
import { LayoutIR } from '../../../components/organisms/Layout/LayoutIR'
import { HeadingTertiary } from '../../../components/atoms/Heading/HeadingTertiary'
import { useLoadRemoteScript } from '../../../components/hooks/useLoadRemoteScript'
import { graph } from '../../../components/styles/graph'
import { SEOIR } from '../../../components/data/SEO'
import { ENGLISH_TITLE } from '../../../components/data/English'

const highlight = css`
  @media screen and (min-width: 1200px) {
    .bg-white {
      width: calc(50% - 20px);
    }
  }
`

const Page = () => {
  // 表の埋め込みに使用。id属性とsrcを指定
  useLoadRemoteScript(
    '#graph01',
    '//www.xj-storage.jp/public-graph-at/table/AS04576/c92ef57b/0323/4716/87e3/9db0fc2a5060/table_ja.js'
  )
  useLoadRemoteScript(
    '#graph02',
    '//www.xj-storage.jp/public-graph-at/table/AS04576/2024b39d/1a42/4954/978b/5917ea556f1d/table_ja.js'
  )
  useLoadRemoteScript(
    '#graph03',
    '//www.xj-storage.jp/public-graph-at/table/AS04576/be002aff/8795/4a30/a8dc/92ec52a198ed/table_ja.js'
  )
  useLoadRemoteScript(
    '#graph04',
    '//www.xj-storage.jp/public-graph-at/table/AS04576/284276ee/b7ca/4700/b140/544975956d2f/table_ja.js'
  )
  useLoadRemoteScript(
    '#graph05',
    '//www.xj-storage.jp/public-graph-at/table/AS04576/3fc8b82c/c722/48cf/89cf/d7268f806fd8/table_ja.js'
  )
  useLoadRemoteScript(
    '#graph06',
    '//www.xj-storage.jp/public-graph-at/table/AS04576/9963c058/5868/45b9/ace9/a84b90aa3ffb/table_ja.js'
  )
  return (
    <LayoutIR
      heading="業績ハイライト"
      headingEnglish={ENGLISH_TITLE.FinancialHighlights}
      BreadcrumbData={[
        { title: '株主・投資家の皆さまへ', url: '/ir' },
        { title: '業績・財務ハイライト', url: '/ir/performance' },
        { title: '業績ハイライト', url: '' },
      ]}
    >
      <div className="pc:flex pc:flex-wrap" css={highlight}>
        <div className="pc:pt-12 pc:px-6 pc:mr-10 pc:pb-6 mb-14 pc:mb-10 bg-white">
          <HeadingTertiary label="売上高" />
          <div css={graph}>
            <img
              src="//www.xj-storage.jp/public-graph-at/graph/AS04576/c92ef57b/0323/4716/87e3/9db0fc2a5060/graph_ja.png"
              alt="売上高"
            />
            <div id="graph01" className="overflow-x-auto mb-2"></div>
            <p className="text-right text-xs pt-2">（百万円）</p>
          </div>
        </div>

        <div className="pc:pt-12 pc:px-6 mb-14 pc:pb-6 pc:mb-10 bg-white">
          <HeadingTertiary label="営業利益" />
          <div css={graph}>
            <img
              src="//www.xj-storage.jp/public-graph-at/graph/AS04576/2024b39d/1a42/4954/978b/5917ea556f1d/graph_ja.png"
              alt="営業利益"
            />
            <div id="graph02" className="overflow-x-auto mb-2"></div>
            <p className="text-right text-xs pt-2">（百万円）</p>
          </div>
        </div>

        <div className="pc:px-6 pc:mr-10 pc:pb-6 pc:pt-12 mb-14 pc:mb-10 bg-white">
          <HeadingTertiary label="経常利益" />
          <div css={graph}>
            <img
              src="//www.xj-storage.jp/public-graph-at/graph/AS04576/be002aff/8795/4a30/a8dc/92ec52a198ed/graph_ja.png"
              alt="経常利益"
            />
            <div id="graph03" className="overflow-x-auto mb-2"></div>
            <p className="text-right text-xs pt-2">（百万円）</p>
          </div>
        </div>

        <div className="pc:px-6 pc:pb-6 pc:pt-12 mb-14 pc:mb-10 bg-white">
          <HeadingTertiary label="親会社株主に帰属する当期純利益" />
          <div css={graph}>
            <img
              src="//www.xj-storage.jp/public-graph-at/graph/AS04576/284276ee/b7ca/4700/b140/544975956d2f/graph_ja.png"
              alt="親会社株主に帰属する当期純利益"
            />
            <div id="graph04" className="overflow-x-auto mb-2"></div>
            <p className="text-right text-xs pt-2">（百万円）</p>
          </div>
        </div>

        <div className="pc:px-6 pc:mr-10 pc:pb-6 pc:pt-12 mb-14 pc:mb-0 bg-white">
          <HeadingTertiary label="1株当たり当期純利益" />
          <div css={graph}>
            <img
              src="//www.xj-storage.jp/public-graph-at/graph/AS04576/3fc8b82c/c722/48cf/89cf/d7268f806fd8/graph_ja.png"
              alt="1株当たり当期純利益"
            />
            <div id="graph05" className="overflow-x-auto mb-2"></div>
            <p className="text-right text-xs pt-2">（円）</p>
          </div>
        </div>

        <div className="pc:px-6 pc:pb-6 pc:pt-12 mb-0 bg-white">
          <HeadingTertiary label="自己資本利益率" />
          <div css={graph}>
            <img
              src="//www.xj-storage.jp/public-graph-at/graph/AS04576/9963c058/5868/45b9/ace9/a84b90aa3ffb/graph_ja.png"
              alt="自己資本純利益率"
            />
            <div id="graph06" className="overflow-x-auto mb-2"></div>
            <p className="text-right text-xs mr-10 pc:mr-8 pt-2">（%）</p>
          </div>
        </div>
      </div>
    </LayoutIR>
  )
}

export default Page

/**
 * headタグの内容
 */
/** */
export const Head = () => (
  <SEOIR title="業績ハイライト" url="/ir/performance/highlight" />
)
